.modal {
  &-bg {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0.8);
  }
  &-content {
    position: absolute;
    width: 80vw;
    padding: 15px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    &-title {
      font-weight: bold;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid rgb(235, 235, 235);
      padding-bottom: 15px;
      margin-bottom: 25px;
    }
    &-body {
    }
    &-footer {
      margin-top: 25px;
      margin-left: auto;
    }
    &-close:hover {
      cursor: pointer;
    }
  }
}

@import "./variable";
@import "./vendor/bootstrap/bootstrap";
@import "./component/icon";
@import "./component/bottom-menu";
@import "./component/card";
@import "./component/fab";
@import "./component/home-menu";
@import "./component/top-menu";
@import "./component/modal";
@import "./component/notif";
@import "./component/center";
@import "./component/table";
@import "./component/loading";
@import "./media";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Helvetica;
  font-size: 14px;
}

.cursor-pointer {
  cursor: pointer;
}

.center-all {
  position: absolute;
  margin-left: 50%;
  margin-top: 50%;
  transform: translate(-50%, -50%);
}

.v1 {
  border-left: 1px solid #c4c4c4;
  margin: 0 10px;
}

.z-link {
  &:hover {
    cursor: pointer;
    color: theme-color("primary");
  }
}

.main-padding {
  padding: 4.3em 0.8em;
}

#root {
  background: #fff;
}

.column-2 {
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 15px;
}

.wallet-value {
  display: block;
  text-align: center;
  small {
    display: block;
    font-size: 9px;
    margin-bottom: -3px;
  }
  span {
    font-weight: bold;
  }
}

.text-big {
  font-size: 30px;
  font-weight: bold;
}

.list-menu div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  margin: 0 5px;
  border-bottom: 1px solid #ececec;
  &:hover {
    cursor: pointer;
  }
}

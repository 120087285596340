.notif {
  &-title {
    font-weight: bold;
    border-bottom: 1px solid white;
    padding-bottom: 4px;
    margin-bottom: 4px;
  }
  &-error {
    position: fixed;
    top: 1em;
    right: 1em;
    color: white;
    max-width: $breakpoint-phone;
    background-color: rgba(158, 4, 4, 0.8);
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
    padding: 1em;
    border-radius: 4px;
  }
}
